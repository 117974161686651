import React, { useState, useEffect } from "react";
import { Card, Input, Button, Divider, Form, Radio, message } from "antd";
import AdminLayout from "./AdminLayout";

import "./AdminUsersPage.less";
import { IUserInfo } from "../../components/AuthProvider";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { formLayout, formTailLayout } from "../FormLayout";
import PageLoading from "../../components/PageLoading";
import { guestUserId } from "../../constants";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

// Fetch data about single user
async function fetchUser(id: number) {
  try {
    const response = await Axios.get("/api/dashboard/users/" + id);
    return response.data as IUserInfo;
  } catch (err) {
    console.error("Failed to fetch user " + id, err.response);
  }
}

export const AdminEditUserPage: React.FunctionComponent = () => {
  const params = useParams();

  const [user, setUser] = useState<IUserInfo>();
  const id = params.userId;

  // We use a separate state variable to tracker access because of the custom
  // layout we want in the form with separate explanations for each Radio items.
  // Ideally we'd just use a Radio.Group and no separate state, but I couldn't
  // get the layout I wanted with that.
  const [access, setAccess] = useState<"regular" | "admin">();

  // Fetch user data on component load
  useEffect(() => {
    fetchUser(Number(id)).then((u) => {
      setUser(u);
      setAccess(u?.admin ? "admin" : "regular");
    });
  }, [id]);

  function handleSubmitForm(values: any): void {
    // TODO: implement
    const { email, name, password } = values;

    Axios.patch("/api/dashboard/users/" + user?.id, {
      name: name,
      email: email,
      admin: access === "admin",
      password: password,
    })
      .then((_res) => {
        message.success(`User ${name} was updated.`);
      })
      .catch((error) => {
        console.error("Failed to update user:", error.response);
        const msgFromServer = error.response?.data?.msg;

        message.error(`Failed to update the user. (${msgFromServer})`);
      });
  }

  const loading = <PageLoading />;
  const loaded = (
    <Card>
      <Divider orientation="left">Account</Divider>

      <Form
        // TODO: the layout sucks on big screens
        {...formLayout}
        name="edit-user"
        initialValues={{
          name: user?.name,
          email: user?.email,
        }}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input disabled={user?.id === guestUserId} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input disabled={user?.id === guestUserId} />
        </Form.Item>

        <Divider orientation="left">Password</Divider>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              type: "string",
            },
          ]}
        >
          <Input.Password
            disabled={user?.id === guestUserId}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          label="Password confirmation"
          name="confirm_password"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const password = getFieldValue("password");

                if (password && !value)
                  return Promise.reject("Please confirm new password.");

                if (!value || password === value) return Promise.resolve();

                return Promise.reject(
                  "The two passwords provided do not match."
                );
              },
            }),
          ]}
        >
          <Input.Password
            disabled={user?.id === guestUserId}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Divider orientation="left">Access</Divider>

        <Form.Item
          name="access_regular"
          label="Access level"
          help={<p>Regular users have access to their canvases and folders.</p>}
        >
          <Radio
            disabled={user?.id === guestUserId}
            value="regular"
            checked={access === "regular"}
            onChange={() => setAccess("regular")}
          >
            Regular
          </Radio>
        </Form.Item>

        <Form.Item
          label={<span />}
          colon={false}
          name="access_admin"
          help={
            <p>
              Administrators have access to all canvases and folders and can
              manage all features in this installation.
            </p>
          }
        >
          <Radio
            disabled={user?.id === guestUserId}
            value="administrator"
            checked={access === "admin"}
            onChange={() => setAccess("admin")}
          >
            Administrator
          </Radio>
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button
            disabled={user?.id === guestUserId}
            type="primary"
            htmlType="submit"
          >
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <AdminLayout selectedSideMenuKey="users">
      {user === undefined ? loading : loaded}
    </AdminLayout>
  );
};
